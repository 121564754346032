<template>
  <div class="AdminActivity" v-if="user && user.roles.includes('super')">
    <section class="ActivityFilter" :key="resetSelect">
      <div class="ActivityFilter__item" @click="$refs.myDatePicker.togglePicker()">
        <button class="ActivityFilter__btn">
          <span v-html="`Fecha`"></span>
          <span v-if="this.$route.query.dateFrom" v-html="` : ${this.$route.query.dateFrom} - ${this.$route.query.dateTo}`"></span>
        </button>
      </div>
      <div class="ActivityFilter__item">
        <select class="ActivityFilter__btn" name="branch" @change="setFilter">
          <option value="allcity">Todas las ciudades</option>
          <option value="medellin">Medellín</option>
          <option value="pereira">Pereira</option>
          <option value="cucuta">Cúcuta</option>
          <option value="bogota">Bogotá</option>
          <option value="other">Otras ciudades</option>
        </select>
      </div>
      <div class="ActivityFilter__item">
        <select class="ActivityFilter__btn" name="type" @change="setFilter">
          <option value="all">Todos los estados</option>
          <option value="approved">Aprobadas</option>
          <option value="pending">Pendientes</option>
          <option value="disapproved">No aprobadas</option>
          <option value="selected">Seleccionadas</option>
          <option value="noselected">No seleccionadas</option>
          <option value="admitted">Admitidas</option>
          <option value="noadmitted">No admitidas</option>
          <option value="waiting">En espera</option>
          <option value="hired">Contratadas</option>
          <option value="deserter">Desertoras</option>
          <option value="waiver">Renuncias</option>
          <option value="endContract">Cancelación contrato</option>
        </select>
      </div>
      <div class="ActivityFilter__item">
        <button class="ActivityFilter__btn" @click="setFilter('reset')">Quitar filtros</button>
      </div>
    </section>
    <v-md-date-range-picker
      ref="myDatePicker"
      @change="handleDate"
      :start-date="this.$route.query.dateFrom"
      :end-date="this.$route.query.dateTo"
    ></v-md-date-range-picker>
    <section class="ActivityList" v-bind="getActivities">
      <div class="ActivityList__content">
        <div class="ActivityList__activity" v-for="(activity, idx) in posts" :key="idx">
          <div class="ActivityList__msg" v-html="showMsgListActivity(activity)"></div>
          <div class="ActivityList__date">{{ formatDate(activity.date) }}</div>
        </div>
        <div class="ActivityList__shoMoreContent" v-if="posts.length < total">
          <button class="ActivityList__showMore click-on-bottom" @click="getPage((cpage += 1))">Ver más</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import router from "../../router";
import api from "@/api/index";
export default {
  data: function () {
    return {
      posts: [],
      page: 1,
      cpage: 1,
      total: null,
      loading: null,
      msgAction: {},
      resetSelect: 1,
      user: null,
    };
  },
  computed: {
    async getActivities(page) {
      page = page >= 1 ? page : 1;
      let _host = this.getRequest(page);
      const { data } = await api.get(_host);
      this.posts = data.allActivities;
      this.total = data.totalActivities;
    },
  },
  async mounted() {
    this.setFilter("reset");
    this.user = await this.$store.state.userProfile;
  },
  methods: {
    async getPage(cpage) {
      this.page = cpage;
      let _host = this.getRequest(this.page);
      const { data } = await api.get(_host);
      this.posts.push(...data.allActivities);
    },
    formatDate: function (date) {
      return this.$global.dateLocaleFormat(date);
    },
    showMsgListActivity: function (activity) {
      this.msgAction = this.$global.dictionary.logActivity;
      let msgShow = `<strong capitalize>${activity.userName}</strong> ${this.msgAction[activity.typeActivity]} la modelo <strong>${
        activity.modelName
      }</strong>`;
      return msgShow;
    },
    setFilter: function (elmn) {
      this.cpage = 1;
      let _push;
      if (elmn === "reset") {
        _push = { path: `/admin/log/?type=allActivity` };
        this.resetSelect++;
      } else {
        let elm = elmn.srcElement;
        let query = this.$route.query;
        let nquery = { [elm.name]: elm.value };
        _push = { query: { ...query, ...nquery } };
      }
      router.push(_push).catch(() => {});
    },
    handleDate: function (data, values) {
      this.setFilter({ srcElement: { name: "dateFrom", value: values[0] } });
      this.setFilter({ srcElement: { name: "dateTo", value: values[1] } });
    },
    getRequest: function (page) {
      let currentQuery = this.$route.fullPath.substring(1);
      if (currentQuery && currentQuery.includes("#")) {
        currentQuery = currentQuery.split("#")[0];
      }
      let _host = `${process.env.VUE_APP_API_HOST}${currentQuery}&page=${page}`;
      return _host;
    },
  },
};
</script>

<style lang="scss">
.AdminActivity {
  .ActivityFilter {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    flex-direction: row;
    justify-content: left;
    top: $header_height;
    width: 100%;
    align-items: center;
    margin: auto;
    overflow-x: auto;
    border-bottom: solid 1px $slider_bg;
    background-color: $white;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 40px;
      max-height: 40px;
      color: $black;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
    }
    &__btn {
      min-height: 40px;
      max-height: 40px;
      padding: 0 $mpadding * 2;
      border: 0;
      border-right: solid 1px $slider_bg;
      background-color: $white;
      font-family: $first_font;
      font-size: 16px;
      white-space: nowrap;
      appearance: none;
      color: $black;
      &:hover {
        background-color: $porcelain;
        cursor: pointer;
      }
    }
  }
  .ActivityList {
    display: block;
    margin-top: -20px;
    &__shoMoreContent {
      text-align: center;
    }
    &__showMore {
      padding: $mpadding;
      border: none;
      background-color: transparent;
      color: $primary_color;
      &:hover {
        transform: scale(0.97);
      }
    }
    &__activity {
      padding: $mpadding;
      border-bottom: 1px solid $slider_bg;
      &:hover {
        z-index: 2;
        background-color: $white;
        box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 #3c40434d, 0 1px 3px 1px #3c404326;
      }
    }
    &__msg {
      display: block;
      padding-bottom: $mpadding/3;
    }
    &__date {
      font-family: $first_font;
    }
  }
  .mdrp__activator {
    display: none;
  }
  .mdrp__panel.dropdown-menu[data-v-7863e830] {
    margin-top: -45px;
  }
  .mdrp__panel .calendar-table.right[data-v-7863e830] {
    display: none;
  }
}
@media (min-width: $tablet_width) {
  .ActivityList {
    &__activity {
      display: flex;
      justify-content: space-between;
    }
    &__msg {
      padding-right: $mpadding * 2;
    }
    &__date {
      min-width: 170px;
    }
  }
}
</style>
